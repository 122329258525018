'use client';

import Link from 'next/link';
import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { LandingPageButton } from '@/components/LandingPage/Button';
import { Container } from '@/components/LandingPage/Container';
import { Logo } from '@/components/LandingPage/Logo';
import { NavLinks } from '@/components/LandingPage/NavLinks';

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>)
{
	return (
		<svg viewBox='0 0 24 24' fill='none' aria-hidden='true' {...props}>
			<path d='M5 6h14M5 18h14M5 12h14' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
}

function ChevronUpIcon(props: React.ComponentPropsWithoutRef<'svg'>)
{
	return (
		<svg viewBox='0 0 24 24' fill='none' aria-hidden='true' {...props}>
			<path d='M17 14l-5-5-5 5' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
}

function MobileNavLink(props: Omit<React.ComponentPropsWithoutRef<typeof Popover.Button<typeof Link>>, 'as' | 'className'>)
{
	return <Popover.Button as={Link} className='block text-base leading-7 tracking-tight text-gray-700' {...props} />;
}

export function Header()
{
	return (
		<header>
			<nav>
				<Container className='relative z-50 flex justify-between py-8'>
					<div className='relative z-10 flex items-center gap-16'>
						<Link href='/' aria-label='Home' className='text-3xl font-bold'>
							maven bio
						</Link>
						<div className='hidden lg:flex lg:gap-10'>
							<NavLinks />
						</div>
					</div>
					<div className='flex items-center gap-4'>
						<Popover className='lg:hidden'>
							{({ open }) => (
								<>
									<Popover.Button
										className='relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ui-not-focus-visible:outline-none'
										aria-label='Toggle site navigation'
									>
										{({ open }) => (open ? <ChevronUpIcon className='h-6 w-6' /> : <MenuIcon className='h-6 w-6' />)}
									</Popover.Button>
									<AnimatePresence initial={false}>
										{open && (
											<>
												<Popover.Overlay
													static
													as={motion.div}
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													exit={{ opacity: 0 }}
													className='fixed inset-0 z-0 bg-gray-300/60 backdrop-blur'
												/>
												<Popover.Panel
													static
													as={motion.div}
													initial={{ opacity: 0, y: -32 }}
													animate={{ opacity: 1, y: 0 }}
													exit={{
														opacity: 0,
														y: -32,
														transition: { duration: 0.2 }
													}}
													className='absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-12 shadow-2xl shadow-gray-900/20'
												>
													<div className='mt-8 flex flex-col gap-2 '>
														<LandingPageButton
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
															href='https://cal.com/team/maven-bio/intro'
															variant='outline'
															className=' border-0 outline-0 hover:bg-neutral-100 active:bg-neutral-200'
														>
															Get a demo
														</LandingPageButton>
														<LandingPageButton
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
															href='https://auth.mavenbio.io/'
															variant='outline'
														>
															Log in
														</LandingPageButton>
														<LandingPageButton
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7' /
															//href='/dashboard'
															href='https://auth.mavenbio.io/en/signup'
															className='bg-maven-primary-400 hover:bg-maven-primary-500 active:bg-maven-primary-600 font-semibold'
														>
															Sign up for free
														</LandingPageButton>
														{/* <Button
															//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
															href='https://cal.com/team/maven-bio/intro'
															variant='outline'
														>
															Request a Trial
														</Button> */}
													</div>
												</Popover.Panel>
											</>
										)}
									</AnimatePresence>
								</>
							)}
						</Popover>
						<LandingPageButton href='https://cal.com/team/maven-bio/intro' variant='outline' className='hidden lg:block font-semibold border-0 outline-0 hover:bg-neutral-100 active:bg-neutral-200'>
							Get a demo
						</LandingPageButton>
						<LandingPageButton href='https://auth.mavenbio.io/' variant='outline' className='hidden lg:block font-semibold'>
							Log in
						</LandingPageButton>
						<LandingPageButton
							//href='/dashboard'
							href='https://auth.mavenbio.io/en/signup'
							className='hidden lg:block bg-maven-primary-400 hover:bg-maven-primary-500 active:bg-maven-primary-600 !font-bold'
						>
							Sign up for free
						</LandingPageButton>
						{/* <Button
							//href='https://forms.gle/YK55KNcyS3B7Ps7P7'
							href='https://cal.com/team/maven-bio/intro'
							variant='outline'
							className='hidden lg:block '
						>
							Request a Trial
						</Button> */}
					</div>
				</Container>
			</nav>
		</header>
	);
}
