import AdaptiveEnumDropdown from '@/components/Enum/AdaptiveEnumDropdown';
import NestedSelectDropdown from '@/components/Enum/NestedSelectDropdownPC';
import MaxLengthTextInput from '@/components/MaxLengthTextInput';
import { Button } from '@/components/ui/button';
import { addFieldFill } from '@/redux/interactionSlice';
import { setOverlay } from '@/redux/layoutSlice';
import store, { RootState } from '@/redux/store';
import useStatefulBitset from '@/utils/hooks/useStatefulBitset';
import BitSet from 'bitset';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Send from '@material-symbols/svg-400/outlined/send.svg';
import Check from '@material-symbols/svg-400/outlined/check.svg';
import Landscape from '@material-symbols/svg-400/outlined/landscape.svg';

import { cn } from '@/utils/lib/utils';
import getCurrentIds from '@/utils/getCurrentIds';
import MockDropdown from './MockDropdown';

const depthOptions = ['Prefer Depth of Analysis', 'Balanced Analysis', 'Prefer Breadth of Analysis'];
const focusAreaOptions = ['Indication', 'Therapeutic Area', 'Mechanism', 'Target', 'Drug Class', 'Product Type'];

const MockLandscapeRequestPanel = () =>
{
	const reportTitle = undefined;
	const focusArea = undefined;
	const depth = undefined;
	const submitted = false;
	const submittable = true;

	return (
		<div className='flex flex-col w-full gap-4 pointer-events-none text-neutral-900 h-[600px]'>
			<div>
				<p className='flex flex-row items-center gap-x-2 text-no-wrap font-semibold text-lg'>
					<span className='text-[24px]'>
						<Landscape />
					</span>
					Custom Landscape Analysis
				</p>
				<p className='text-sm text-neutral-400 pb-2'>
					Define your high-level requirements and our AI Analyst will prepare a custom landscape analysis suited to your requirements.
					{/*You will receive an email when it is ready for review. */}
				</p>
			</div>

			<div>
				<p>Report Title</p>
				<div className='text-sm text-neutral-400 pb-2'>Provide a short title for your report.</div>
				<div className='border border-neutral-300 p-2 rounded'>{reportTitle || 'Report Title'}</div>
			</div>

			<div className='flex flex-col'>
				<p>Landscape Type</p>
				<p className='text-sm text-neutral-400 pb-2'>Choose how you want to define your landscape: by indication or by target.</p>
				<MockDropdown text='Select Landscape Type' placeholder={false} />
			</div>

			<div>
				<p>Specific areas of focus</p>
				<p className='text-sm text-neutral-400 pb-2'>Select the area(s) you'd like to focus your landscape report on.</p>
				<div className='border border-neutral-300 p-2 rounded'>Select specific areas of focus</div>
			</div>

			<div className='hidden'>
				<p>Development Stage Focus</p>
				<p className='text-sm text-neutral-400 pb-2'>[Optional] Restrict the landscape analysis to specific phases of development</p>
				<div className='border border-neutral-300 p-2 rounded'>{'Consider All Phases'}</div>
			</div>

			<div className='hidden'>
				<p>Geographic Region Focus</p>
				<p className='text-sm text-neutral-400 pb-2'>[Optional] Focus analysis on activity within specific geographies</p>
				<div className='border border-neutral-300 p-2 rounded'>{'Consider All Geographies'}</div>
			</div>

			<div className='hidden'>
				<p>Depth vs. Breadth of Analysis</p>
				<p className='text-sm text-neutral-400 pb-2'>
					[Optional] Calibrate the depth (# of assets) versus breadth (detail on assets) included in the report. A focus on breadth will result in a
					harsher filter when deciding if an asset should be included in the analysis. Conversely, a focus on breadth will result in more assets being
					analyzed, at a higher-level.
				</p>
				<div className='border border-neutral-300 p-2 rounded'>{depth || 'Select Depth vs. Breadth'}</div>
			</div>

			{submitted ? (
				<div className='hover:bg-maven-primary-600 bg-maven-primary-600'>
					<p className='flex flex-row items-center gap-x-2 font-bold'>
						Submitting...{' '}
						<span className='pl-2 text-[24px]'>
							<Check />
						</span>
					</p>
				</div>
			) : submittable ? (
				<div className='bg-maven-primary-400 text-white p-2 rounded-md'>
					<p className='flex flex-row items-center gap-x-2 justify-center'>
						Submit Request{' '}
						<span className='pl-2 text-[24px]'>
							<Send />
						</span>
					</p>
				</div>
			) : (
				<div className='text-neutral-700 bg-neutral-50 border-neutral-400 rounded-md cursor-not-allowed'>Please fill all required fields</div>
			)}
		</div>
	);
};

export default MockLandscapeRequestPanel;
